import React from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom"

import Home from './components/Home'
import Login from './components/Login'
import ScanTrackTransport from './components/ScanTrackTransport'
import ScanTrackCover from './components/ScanTrackCover'
import Error404 from './components/Error404'

function Routers() {
    return <BrowserRouter >
        <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/scan-track-transport' component={ScanTrackTransport} />
            <Route exact path='/scan-track-cover' component={ScanTrackCover} />
            <Route exact path="/login"  component={Login} />
            <Route component={Error404} />
        </Switch>
    </BrowserRouter>
}
export default Routers;