import React, { useState } from 'react'
import axios from 'axios'
import { API_URL} from '../constants'
import { Redirect } from "react-router-dom"
import { useForm } from 'react-hook-form'
import LoadingPage from './LoadingPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import setAuthorizationToken from '../setAuthorizationToken'

function Login() {
    const { register, handleSubmit, watch, errors, setValue } = useForm()
    const [loading, setLoading] = useState(false)
    const [isLogin, setIsLogin] = useState(localStorage.isLoginWarehouse)
    const [error, setError] = useState('')

    const handleLogin = () => {
        let username = watch('username')
        let password = watch('password')

        setError('')
        setLoading(true)
        axios.post(`${API_URL}/admin/login`, { username: username, password: password })
            .then(res => {
                if (res.data.success) {
                    const token = res.data.access_token
                    const refresh = res.data.access_refresh

                    localStorage.setItem('jwtToken', token)
                    localStorage.setItem("refresh_token", refresh)
                    localStorage.setItem('apiUrl', API_URL)
                    localStorage.setItem('admin', res.data.user.id)
                    localStorage.setItem('adminNmae', res.data.user.name)
                    localStorage.setItem('isLoginWarehouse', true)

                    setIsLogin(true)
                    setAuthorizationToken(token)
                } else {
                    setError('Username หรือ Password ไม่ถูกต้อง!!')
                }
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
            })
    }

    if (isLogin)
        return <Redirect to="/" />
    else
        return (
            <div className="container">
                <LoadingPage active={loading} />
                <div className="row justify-content-md-center mt-5">
                    <div className="col col-lg-4 col-md-4">
                        {/* <div className="text-center text-muted">Warehouse Management System</div> */}
                        <div className="card mt-3">
                            <div className="card-header text-center">Warehouse Management System</div>
                            <div className="card-body">
                                {error != '' ? <div className="text-danger text-center fn-12 mb-2">*{error}</div> : ''}
                                <form onSubmit={handleSubmit(handleLogin)}>
                                    <div className="form-group">
                                        <label>Username</label>
                                        <input type="text" ref={register({ required: 'Required' })} name="username" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" ref={register({ required: 'Required' })} name="password" className="form-control" />
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary"><FontAwesomeIcon icon={faSignInAlt} /> Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default Login
