import React from 'react'

function Error404() {
    return (
        <div className="content">
            <div style={{ padding: "50px" }}>
                ERROR 404
            </div>
        </div>
    )
}

export default Error404
