import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, Redirect } from "react-router-dom"


import LoadingPage from './LoadingPage'
import MenuMain from '../layout/MenuMain'

function Home() {
    const [isLogin, setIsLogin] = useState(localStorage.isLoginWarehouse)
    const [loading, setLoading] = useState(false)

    if (!isLogin)
        return <Redirect to="/login" />
    else
        return (
            <div>
                <LoadingPage active={loading} />
                <MenuMain />
                <div className="container mt-3">
                    <div class="row text-center">
                        <div className="col-lg-3 col-md-4 col-6">
                            <div className="box box-solid">
                                <div className="box-body text-center">
                                    <Link to={'/scan-track-transport'}><img src="/images/icon-scan-zone.png" alt="ยิงแทร็คบิลขนส่ง" className="img-menu" /></Link>
                                    <div className="fn-menu">เช็คแทร็คบิลขนส่ง</div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-4 col-6">
                            <div className="box box-solid">
                                <div className="box-body text-center">
                                    <Link to={'/warehouse/scan-track-cover'}><img src="/warehouse/images/icon-scan-zone.png" alt="ยิงออกใบคุม" className="img-menu" /></Link>
                                    <div className="fn-menu">ยิงออกใบคุม</div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
}

export default Home
