import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../constants'
import { useForm } from 'react-hook-form'
import { Link, Redirect } from "react-router-dom"
import Swal from 'sweetalert2'
import UseJquery from './UseJquery'
import LoadingPage from './LoadingPage'
import MenuMain from '../layout/MenuMain'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faCheckCircle, faCopy, faSyncAlt, faShareSquare, faUser } from '@fortawesome/free-solid-svg-icons'

function ScanTrackTransport() {
    const [isLogin, setIsLogin] = useState(localStorage.isLoginWarehouse)
    const [loading, setLoading] = useState(false)
    const jquery = UseJquery()
    const focus = useRef()
    const [track, setTrack] = useState(null)
    const [errorMsg, setErrorMsg] = useState('');
    const [barcode, setBarcode] = useState('')
    const [transportId, setTransportId] = useState(0)
    const [transportCode, setTransportCode] = useState('บิลขนส่ง')
    const [billCode, setBillCode] = useState('ออเดอร์')
    const [trackingData, setTrackingData] = useState([])
    const [errorColor, setErrorColor] = useState('text-primary')
    const [trackCheck, setTrackCheck] = useState([])
    const [system, setSystem] = useState(0)
    const [remark, setRemark] = useState('')

    useEffect(() => {
        focus.current.focus()
    }, [])

    useEffect(() => {
        if (trackCheck.length) {
            if (trackCheck.length === trackingData.length) {
                handleNextStep()
            }
        }
    }, [trackCheck])

    const handleInput = e => {
        // console.log('keyCode', e.key)
        var value = (e.target.value).toUpperCase()
        setBarcode(value)
        if (e.key === 'Enter') {
            handleEnter(value, false)
        }
    }

    const handleConfirmNextStep = () => {
        if (system && transportId) {
            Swal.fire({
                title: 'ยืนยันข้ามไปขั้นตอนที่ 3',
                type: 'warning',
                width: 350,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ตกลง'
            }).then((result) => {
                if (result.value) {
                    handleNextStep()
                }
            })

        }
    }

    const handleNextStep = () => {
        setLoading(true)
        console.log('localStorage', localStorage.getItem('admin'))

        axios.post(`${API_URL}/admin/warehouse/next-step-third`, { 
            transport_id: transportId, 
            transport_code: transportCode, 
            system: system, 
            remark: remark,
            admin_id:localStorage.getItem('admin'),
            admin_name:localStorage.getItem('adminNmae')
        })
            .then(res => {
                setLoading(false)
                if (res.data.error == 200) {
                    handleReset()
                } else {
                    setErrorMsg(res.data.errorMsg)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const handleEnter = (value, nextBill) => {
        if (value) {
            setTrack(value)
            jquery('#barcode').val('')
            console.log('handleEnter')
            // scanTrackToZone(dispatch, zoneId, zoneName, value)
            setLoading(true)
            setErrorMsg('')
            setErrorColor('text-primary')

            if (!trackingData.length || nextBill) {
                axios.post(`${API_URL}/admin/warehouse/check-track-transport`, { code: value, transport_id: transportId, system: system })
                    .then(res => {
                        setLoading(false)
                        setErrorMsg(res.data.errorMsg)
                        if (res.data.error == 200) {
                            setErrorColor('text-success')
                            setTransportId(res.data.transportId)
                            setTransportCode(res.data.trackingsCode)
                            setBillCode(res.data.billCode)
                            setTrackingData(res.data.trackings)
                            setSystem(res.data.system)
                        } else {
                            setErrorColor('text-danger')
                            setTransportCode('บิลขนส่ง')
                            setBillCode('ออเดอร์')
                            setSystem('0')
                        }
                    })
                    .catch(error => {
                        setLoading(false)
                    })
            } else {
                setLoading(false)
                if (trackingData.includes(value)) {
                    setErrorColor('text-success')
                    console.log('value', value)
                    if (!trackCheck.includes(value)) {
                        setTrackCheck([...trackCheck, value])
                    }
                } else {
                    setErrorMsg('แทร็คกิ้งไม่อยู่ในบิลขนส่งนี้!!')
                    setErrorColor('text-danger')
                }
            }
        }
    }

    const handleReset = () => {
        setTransportCode('บิลขนส่ง')
        setBillCode('ออเดอร์')
        setTransportId(0)
        setTrackingData([])
        setTrackCheck([])
        setTrack(null)
        setErrorMsg('')
        setErrorColor('text-primary')
        setSystem(0)
        setRemark('')
        jquery('#remark').val('')
        focus.current.focus()
    }

    if (!isLogin)
        return <Redirect to="/login" />
    else
        return (
            <>
                <LoadingPage active={loading} />
                <MenuMain />
                <div className="container">
                    <div className="box-center mt-2">
                        <div className="box box-solid">
                            <div className="box-body">
                                <input ref={focus} id="barcode" type="text" className="form-control bg-yl text-center input-barcode" onKeyPress={handleInput} autoComplete="off" />
                                <div className="row no-gutters mt-2">
                                    <div className="col-4">
                                        <h6 className="text-center m-0 mt-1">{billCode}</h6>
                                    </div>
                                    <div className="col-4 text-mid" style={{ borderLeft: '1px double #ccc' }}>
                                        <h6 className="text-center m-0 mt-1">{transportCode}</h6>
                                    </div>
                                    <div className="col-4" style={{ borderLeft: '1px double #ccc' }}>
                                        <div className="text-center m-0">
                                            <button className="btn btn-light btn-sm" onClick={() => jquery('#modal-admin').modal('show')}>หมายเหตุ</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center text-danger mt-1">{errorMsg}</div>
                                <h4 className="text-center"><span className={errorColor}>{track}</span></h4>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        {transportId ? <button className="btn btn-info btn-sm mr-2" onClick={() => handleConfirmNextStep()}><FontAwesomeIcon icon={faShareSquare} /> ไปขั้นตอน 3</button> : null}
                        {transportId ? <button className="btn btn-primary btn-sm" onClick={() => handleEnter(transportCode, true)}><FontAwesomeIcon icon={faCopy} /> เลขบิลซ้ำ</button> : null}
                        <button className="btn btn-danger btn-sm ml-2" onClick={() => handleReset()}><FontAwesomeIcon icon={faSyncAlt} /> เริ่มใหม่</button>
                    </div>
                    {remark && <div className="text-danger">หมายเหตุ : {remark}</div>}
                    <div className="box mt-1">
                        <div className="box-body">
                            <table className="table table-sm table-striped" style={{ fontSize: 12 }}>
                                <tbody>
                                    <tr>
                                        <th className="text-center" >#</th>
                                        <th className="text-center">status</th>
                                        <th>Tracking</th>
                                    </tr>
                                    {trackingData.length ? trackingData.map((item, i) => {
                                        if (trackCheck.includes(item)) {
                                            return <tr key={i} className="text-success">
                                                <td className="text-center">{i + 1}.</td>
                                                <td className="text-center"><FontAwesomeIcon icon={faCheckCircle} /></td>
                                                <td>{item}</td>
                                            </tr>
                                        } else {
                                            return <tr key={i}>
                                                <td className="text-center">{i + 1}.</td>
                                                <td className="text-center text-muted"><FontAwesomeIcon icon={faInfoCircle} /></td>
                                                <td>{item}</td>
                                            </tr>
                                        }
                                    }) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="modal-admin">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">หมายเหตุ</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <textarea className="form-control bg-yl" name="remark" id="remark" rows={3} onChange={(e) => setRemark(e.target.value)} defaultValue={remark || ''} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger btn-lg btn-block" data-dismiss="modal">ปิด</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}

export default ScanTrackTransport
