import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../constants'
import LoadingPage from './LoadingPage'
import MenuMain from '../layout/MenuMain'
import { Redirect } from "react-router-dom"
import UseJquery from './UseJquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

function ScanTrackCover() {
    const [isLogin, setIsLogin] = useState(localStorage.isLoginWarehouse)
    const [loading, setLoading] = useState(false)
    const focus = useRef()
    const [barcode, setBarcode] = useState('')
    const [trackData, setTrackData] = useState([])
    const jquery = UseJquery()

    useEffect(() => {
        focus.current.focus()
    }, [])

    const handleInput = e => {
        // console.log('keyCode', e.key)
        var value = (e.target.value).toUpperCase()
        setBarcode(value)
        if (e.key === 'Enter') {
            setLoading(true)
            jquery('#barcode').val('')
            axios.post(`${API_URL}/admin/warehouse/track-cover-excel`, { code: value, })
                .then(res => {
                    setLoading(false)
                    if (res.data.error == 200) {

                    } else {

                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }

    if (!isLogin)
        return <Redirect to="/login" />
    else
        return <>
            <LoadingPage active={loading} />
            <MenuMain />
            <div className="container">
                <div className="box-center mt-2">
                    <div className="box box-solid">
                        <div className="box-body">
                            <input ref={focus} id="barcode" type="text" className="form-control bg-yl text-center input-barcode" onKeyPress={handleInput} autoComplete="off" />
                            <h6 className="text-center m-0 mt-3">{barcode}</h6>
                        </div>
                    </div>
                </div>
                <div className="box mt-1">
                    <div className="box-body">
                        <table className="table table-sm table-striped" style={{ fontSize: 12 }}>
                            <tbody>
                                <tr>
                                    <th className="text-center" >#</th>
                                    <th className="text-center">เลขบิลขนส่ง</th>
                                </tr>
                                {trackData.length ? trackData.map((item, i) => {
                                    return <tr key={i}>
                                        <td className="text-center">{i + 1}.</td>
                                        <td>{item}</td>
                                    </tr>
                                }) : null}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>

}

export default ScanTrackCover
