
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faSpinner} from '@fortawesome/free-solid-svg-icons'

function LoadingPage(props) {
    if (props.active)
        return (
            <div className="loading-process">
                <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
        )
    return '';
}

export default LoadingPage
