import React, { useState } from 'react';
import axios from 'axios'
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faHome } from '@fortawesome/free-solid-svg-icons'

function MenuMain() {
    const [isLogout, setIsLogout] = useState(false)

    const handleLogout = () => {
        console.log('logout')
        localStorage.clear()
        delete axios.defaults.headers.common['Mg-Type'];
        delete axios.defaults.headers.common['Authorization'];
        setIsLogout(true)
    }

    if (isLogout) {
        return <Redirect to="/login" />
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <Link to="/" className="navbar-brand"> <FontAwesomeIcon icon={faHome} /></Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                    {/* <li className="nav-item active">
                                <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link disabled" href="#" tabIndex={-1} aria-disabled="true">Disabled</a>
                            </li> */}
                </ul>
                <div className="form-inline my-2 my-lg-0">
                    <button type="button" className="btn btn-light btn-sm" onClick={() => handleLogout()}><FontAwesomeIcon icon={faPowerOff} /> Logout</button>
                </div>
            </div>
        </nav>
    )
}

export default MenuMain
